@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700&display=swap');

*{
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
}

body{
  width: 100%;
}

html{
  scroll-behavior: smooth;
  scroll-padding-top:150px;
}

.myBtn {
  display: block;
  position: fixed;
  bottom: 30px;
  right: 20px;
  z-index: 99;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 4px;
}

.none {
  display: none;
}
.box::after{
  content: -webkit-linear-gradient(0deg, rgba(255, 170, 201,0.5) 55%, rgba(255,255, 255, 1) 10%);
}
.box1::after{
  content: -webkit-linear-gradient(100deg, rgba(255, 255, 255, 0.5) 20%, rgba(255,170, 201, 0.5) 70%);
}
.box2::after{
  content: -webkit-linear-gradient(50.33deg, rgba(129, 212, 249, 0.5) 50%, rgba(255, 170, 201, 0.5) 10%);
}
.box3::after{
  content: -webkit-linear-gradient(50.33deg, rgba(129, 212, 249, 0.5) 50%, rgba(255, 170, 201, 0.5) 10%);
  backdrop-filter: blur(4px);
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
